html,
body {
  margin: 0;
  padding: 0;
}

@keyframes ringBell {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  25% {
    transform: rotate(25deg);
  }

  50% {
    transform: rotate(0deg);
    opacity: 0.75;
  }

  75% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}

.ringingBell {
  animation-duration: 0.666s;
  animation-name: ringBell;
  animation-timing-function: linear;
  border-radius: 100px;
  /* animation-iteration-count: infinite; */
  animation-iteration-count: 10;
}
